import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import Modal from "@mui/material/Modal";
import { PhoneInput } from 'react-international-phone';
import { StaticDatePicker, MobileDatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useState, useEffect } from "react";
import { MenuItem, Select } from "@mui/base";
import { BusinessType } from "../types";

const debug = false;

const FALLBACK_BUSINESS_TYPE = BusinessType.GROUP_BOOKING;
const MIN_STATIC_DATE_PICKER_WINDOW_WIDTH = 545;


export default function NewBooking() {

	// First, get business name from query string
	const params = new URLSearchParams(window.location.search);
	const business = params.get("b"); // ObjectID of the business
	const restaurant = params.get("rest"); // Either 'true' or 'false', indicating whether the business is a restaurant

	/* Form information */
	const [phone, setPhone] = useState("");
    const [time, setTime] = useState("00:00");
    const [date, setDate] = useState(new Date());
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	/* Business Information */
	const [businessName, setBusinessName] = useState("");
	const [businessId, setBusinessId] = useState("");
	const [businessType, setBusinessType] = useState(restaurant == "false" ? BusinessType.ONE_ON_ONE : BusinessType.GROUP_BOOKING);

	/* Page state */
	const [submitted, setSubmitted] = useState(false);
	const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

	function handleResize(e: UIEvent) {
		setWindowSize(window.innerWidth);
	}
	
	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return (() => window.removeEventListener("resize", handleResize));
	});


	async function getBusinessData(_id: string) {
		try {
			const r = await fetch(`/get-business-name?id=${_id}`);

			switch(r.status) {
				case 400:
					// Request was bad (malformed URL)
					break;
				case 404:
					// No business data was found
					break;
				default:
					// Success
					console.log("Success");
					const name = await r.text();
					
					setBusinessName(name);
					break;
			}
		} catch {
			console.log("Can't fetch info.");
			return;
		}
	}

	// Now request the business information
	useEffect(() => {
		if(!debug) {
			getBusinessData(business || "");
		}
	}, []);

	/*
	Necessary Info: 
	- First name
	- Last name
	- Date
	- Time
	- Phone
	- Email (optional)
	*/
	
	return (
		<div>
			<Modal open={submitted} onClose={() => setSubmitted(false)}>
				<div className="m-auto w-fit p-8 rounded-xl mt-[30vh] bg-white">
					<h1 className="text-heading font-bold text-5xl">Your booking request has been sent to {businessName || ""}.</h1>
					<h2 className="text-main text-3xl mt-8">We'll notify you when {businessName || ""} has processed your booking request.</h2>
				</div>
			</Modal>

			<h1 className="text-4xl font-semibold p-12 pb-8 text-center text-heading">Complete Reservation Request</h1>

        	<LocalizationProvider dateAdapter={AdapterDayjs}>	
				<div className="w-full mb-16">
					<form className="w-screen md:w-[80%] lg:w-[60%] justify-center m-auto" >
						
                        <input type="hidden" name="business_id" value={params.get("b") || ""}></input>

						{
						businessType != BusinessType.NON_APPOINTMENT && 
						<div className="bg-white rounded-2xl p-8 mb-8 pt-4">
							<h1 className="text-2xl font-semibold text-main mb-4">{businessName || ""}</h1>
							
							{
							businessType == BusinessType.GROUP_BOOKING && 
							<TextField type="number" label="Number of People" fullWidth variant="filled" defaultValue={2}/>
							}
							
							<div className="h-4"/>

							{
							windowSize > MIN_STATIC_DATE_PICKER_WINDOW_WIDTH ?
							<StaticDatePicker className="w-full" onChange={
								(value: any) => { 
									console.log(value["$d"]);
									setDate(value["$d"])
								}} 
							defaultValue={dayjs()}
							orientation="landscape"
							sx={{
								'.MuiPickersToolbar-root': {
									backgroundColor: "#e8e8e8",
								},
								'.MuiPickersLayout-contentWrapper': {
									backgroundColor: "#e8e8e8",
									borderRadius: "4px"
								},
								'.MuiDialogActions-root': {
									backgroundColor: "#e8e8e8"
								}
							}}
							/>
							:
							<MobileDatePicker 
							label="Date"
							className="w-full" 
							onChange={
								(value: any) => { 
									console.log(value["$d"]);
									setDate(value["$d"])
								}} 
							defaultValue={dayjs()}
							/>
							}



							<input type="hidden" value={JSON.stringify(date)} name="date"></input>

							<div className="h-4"/>

							<TimePicker className="bg-white w-full" label="Time" onChange={
								(value : any) => { 
									setTime(`${value["$H"]}:${value["$m"]}`) 
								}
							}
							sx={{
								'.MuiInputBase-root': {
									backgroundColor: "#e8e8e8",
								}
							}}/>
							<input type="hidden" value={time} name="time"></input>
						</div>
						}


						<div className="bg-white rounded-2xl p-8 pt-4 mb-8">
							<h1 className="text-xl text-heading font-semibold mb-4">Contact Information</h1>

							<TextField label="Email address" name="email" fullWidth variant="filled"/>

							<div className="flex flex-row justify-between mt-4">
								<TextField label="First Name" required onChange={
									(e) => setFirstName(e.target.value)
								}
								variant="filled"
								className="w-full md:w-[48%]"
								/>
                                <input name="first_name" type="hidden" value={firstName}></input>

								<TextField label="Last Name"  required onChange={
									(e) => setLastName(e.target.value)
								}
								variant="filled"
								className="w-full md:w-[48%]"
								/>
                                <input name="last_name" type="hidden" value={lastName}></input>
							</div>
							
							<div className="flex justify-center">
								<PhoneInput 
									defaultCountry="au"
									value={phone}
									onChange={(value) => {setPhone(value);}}
									className="z-10 mt-4"
									name="phone"
								/>
							</div>
						</div>
						
						{/* Submission buttons */}
						<div className="w-full text-center">
							<Button type="button" variant="contained" onClick = {async () => {
                                const formInfo = {
                                    date: date,
                                    time: time,
                                    //email: email,
                                    first_name: firstName,
                                    last_name: lastName,
                                    phone: phone,
                                    business_id: params.get("b") || "",
                                }

                                console.log(formInfo);

                                await fetch("/request-booking", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify(formInfo)
                                });
                            }}>
								Submit{businessType !== BusinessType.NON_APPOINTMENT ? " booking" : ""}
							</Button>
						</div>
					</form>
				</div>
			</LocalizationProvider>
		</div>
	);
}
