import { createBrowserRouter, RouterProvider } from "react-router-dom"

// Page imports
import Landing from "./landing-page/landing";
import Login from "./login/login";
import Signup from "./login/signup";
import Overview from "./overview/overview";
import Bookings from "./bookings/bookings";
import Reminders from "./reminders/reminders";
import MarketingPortal from "./marketing/marketing";
import AiPage from "./ai/ai";
import AdGeneration from "./ads/ads";
import SettingsPage from "./settings/settings";
import PendingBookings from "./pending-bookings/pending-bookings";
import NewBooking from "./bookings/new-booking";

import ContactProfile from "./bookings/profile";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Landing />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/overview",
        element: <Overview />
    },
    {
        path: "/signup",
        element: <Signup />
    },
    {
        path: "/bookings",
        element: <Bookings />
    },
    {
        path: "/reminders",
        element: <Reminders />
    },
    {
        path: "/marketing",
        element: <MarketingPortal />
    },
    {
        path: "/ai-insights",
        element: <AiPage />
    },
    {
        path: "/new-ad",
        element: <AdGeneration />
    },
    {
        path: "/settings",
        element: <SettingsPage />
    },
	{
		path: "/pending",
		element: <PendingBookings />
	},
	{
		path: "/new-booking",
		element: <NewBooking />
	},
	{
		path: "/contact-profile",
		element: <ContactProfile />
	}
])

export default function PageRouter() {

    return(
        <RouterProvider router={router} />
    );
}
