import { Button, Card, TextField } from "@mui/material";
import { useState } from "react";

type aiData = {
    summary: string,
    demographics: string,
    ad_goals: string
}

export default function AiConfig({ aiSupplement, updateUserDataCallback }: { aiSupplement: aiData, updateUserDataCallback: (() => void) }) {

    const [summary, setSummary] = useState("");
    const [demographics, setDemographics] = useState("");
    const [adGoals, setAdGoals] = useState("");

    async function submitChanges(e: React.FormEvent<HTMLButtonElement>) {
        e.preventDefault();

        let reqBody = {
            summary: summary,
            demographics: demographics,
            ad_goals: adGoals
        };

        // Check if the fields are empty, if so, attempt to automatically
        // populate them
        if(aiSupplement) {
            if(summary === "") {
                reqBody.summary = aiSupplement.summary;
            }
            if(demographics === "") {
                reqBody.demographics = aiSupplement.demographics;
            }
            if(adGoals === "") {
                reqBody.ad_goals = aiSupplement.ad_goals;
            }
        } 

        let res = await fetch("/update-ai", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(reqBody),
        });

        console.log(`AI Update response: ${res.status}`);
        if(res.status !== 200) {
            alert("There was an error updating your business information.");
            return;
        }

        // Update user data
        updateUserDataCallback();
    }

    return (
        <div>
            <form className="flex flex-col p-2 md:p-8" action="/update-ai" method="post">

                <h1 className="text-xl text-heading mt-2">Business Background Information</h1>
                <p className="text-md mb-8">This form provides crucial background information for the AI analysis algorithm</p>
					
                <p className="mb-4 mt-6 text-lg">Describe your business, what's unique about them?</p>
                <TextField 
                    required 
                    multiline 
                    label="Business Description" 
                    name="summary" 
                    defaultValue={(aiSupplement && aiSupplement.hasOwnProperty("summary")) ? aiSupplement["summary"] : ""} 
                    onChange={(e) => setSummary(e.target.value)}
                />

                <p className="mb-4 mt-6 text-lg">Briefly describe your target audience.</p>
                <TextField 
                    required 
                    multiline 
                    label="Target Audience Description" 
                    name="demographics" 
                    defaultValue={(aiSupplement && aiSupplement.hasOwnProperty("demographics")) ? aiSupplement["demographics"] : ""}
                    onChange={(e) => setDemographics(e.target.value)}
                />

                <p className="mb-4 mt-6 text-lg">What is the goal of your ad campaigns? (e.g. drive sales, website visits, increase bookings)</p>
                <TextField 
                    required 
                    multiline 
                    label="Ad Goals Description" 
                    name="ad_goals" 
                    defaultValue={(aiSupplement && aiSupplement.hasOwnProperty("ad_goals")) ? aiSupplement["ad_goals"] : ""}
                    onChange={(e) => setAdGoals(e.target.value)}
                />

                <div className="mt-12"></div>

                <Button 
                    variant="contained" 
                    type="button" 
                    onClick={(e) => submitChanges(e)}>
                        Submit and regenerate analysis
                </Button>

            </form>
        </div>
    );
}
