import  React, { useState, useEffect, useContext } from 'react'
import Sidebar from '../ui/sidebar';
import TopNav from '../ui/top-nav';
import { AuthContext } from "../auth";

// Icon imports
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { parseMDate, formatDateTime } from "../types";



export default function PendingBookings() {
	
	const authInfo = useContext(AuthContext);

	const [id, setId] = useState("");

	if(!authInfo) throw new Error("Invalid auth context.");
	const userData = authInfo.userData;

	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
		getId();

	}, [authInfo.fetchingAuth]);


	function getId() {
		
		// Retrieve id from browser cookies
		let cookieValue = document.cookie.split(';').find(item => item.trim().startsWith('account_id='));
		const id = cookieValue ? cookieValue.split('=')[1] : "";
		
		setId(id);
	}


	function formatPendingBooking(pendingBooking: any) {

		return (
			<li className="flex flex-row justify-evenly pt-4 pb-4">
				<div className="">
					<h1 className="font-bold">{
                        formatDateTime(parseMDate(pendingBooking["appt_date"]))
                    }</h1>
					<h2>{pendingBooking["first_name"]} {pendingBooking["last_name"]}</h2>
					<h2>{pendingBooking["phone"]}</h2>
				</div>

				<div className="bg-slate-100 rounded-xl p-2 ">
					<h1>Notes:</h1>
					<p>{pendingBooking["general_info"]}</p>
				</div>

				<div className="flex flex-col">
					<button className="bg-slate-200 hover:bg-[#d2fcca] transition-all rounded-full w-8 h-8 mb-2" onClick={async () => {

						// Notify server
						const r = await fetch(`/accept?id=${pendingBooking._id.$oid}`, {
							method: "POST",
							//headers: {"Content-Type": "application/json"},
							//body: JSON.stringify({"id": pendingBooking["_id"]})
						});

						const s = r.status;
						// Fetch new data
						if(authInfo) authInfo.fetchUserData();

					}}><DoneIcon /></button>
					<button className="bg-slate-200 hover:bg-[#fccaca] transition-all rounded-full w-8 h-8" onClick={async () => {
						// Notify server
						const r = await fetch(`/deny?id=${pendingBooking._id.$oid}`, {
							method: "POST",
							//headers: {"Content-Type": "application/json"},
							//body: JSON.stringify({"id": pendingBooking["_id"]})
						});
						const s = r.status;
						// Fetch new data
						if(authInfo) authInfo.fetchUserData();
						
						
					}}><CloseIcon /></button>
				</div>
			</li>
		);
	}

	function pendingBookingList(): Array<JSX.Element> {
		let arr = new Array<JSX.Element>();
		
		if(!userData) return arr;

		arr.push(<hr />); // Add top line
		userData.pending_bookings.forEach((b: any) => {
			arr.push(formatPendingBooking(b))
			arr.push(<hr />);
		});
		
		return arr;
	}



	return (
	<div>
        <Sidebar />
       

        <TopNav/>

        <main className="md:ml-64 p-8 pt-28">

        	<h1 className="text-heading text-2xl pb-8 text-center md:text-left">Manage Booking Requests</h1>

            <div className="bg-white w-full m-auto pl-8 pr-8 pt-8 justify-center content-center rounded-md pb-8">

				<div className="bg-slate-100 w-fit p-8 pt-2 pb-2 rounded-xl m-auto ">
					<span className="font-bold">Your Booking Page Link: </span> <a className="underline block md:inline" href={`https://startbase.site/new-booking?b=${id}`}>{`https://startbase.site/new-booking?b=${id}`}</a>
					<hr className="mt-4"/>
					<p className="p-6 pt-4">
						You can provide this link to customers, and they can directly request bookings through it. The requests will appear here. Once approved the bookings will automatically enter the system.
					</p>
					<hr className="mb-4"/>
				</div>

				<h1 className="text-xl font-semibold mt-6 text-center md:text-left">Currently Pending Bookings</h1>
				<div>
					{pendingBookingList().length === 0 && <h2 className="mt-6 text-start">You don't have any currently pending requests!</h2>}
				</div>


				<ul className="m-auto md:w-1/2">
					{pendingBookingList()}
				</ul>

			</div>
		</main>
	</div>
	);
}
