import { useState, useEffect, useContext } from "react";
import Sidebar from "../ui/sidebar";
import AiConfig from "./ai-config";
import TopNav from "../ui/top-nav";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { AuthContext } from "../auth";

import { marked } from "marked";


export default function AiPage() {

	const authInfo = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

	if(!authInfo) throw new Error("Invalid auth context");
	const userData = authInfo.userData;
	
	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
	}, [authInfo.fetchingAuth]);


    async function fetchNewAiAnalysis() {

        // Start loading animation on AI textbox
        setIsLoading(true);

        let res = await fetch("/get-ai-analysis");

        if(res.status !== 200) {
            alert("There was an error generating a new AI analysis.");
        }

        setTimeout(() => {
            if(authInfo !== null) {
                authInfo.fetchUserData();
            }
        }, 1000*20);

        // Remove loading animation from AI textbox
        setIsLoading(false);
        
    }
    // If the user has a valid AI analysis, set the response string.
    let aiString = "Connect your Google Account to Access AI Campaign Analysis.";

    if(authInfo.googleAuthed && userData?.hasOwnProperty("account_data")) {
        let rawStr = userData.account_data.api_cache.ai;
        rawStr = rawStr.replace('"', "");
        rawStr = rawStr.replace(/\\n/g, '\n');

        console.log(rawStr);

        aiString = marked.parse(rawStr, {
            async: false,
            breaks: true,
            gfm: true
        });
    }     

    return (
        <div className="bg-slate-50 h-screen">
            <Sidebar />

            <TopNav/>

            <main className="md:ml-64 p-8 pt-28" >
                
				<h1 className="text-2xl mb-8 text-heading">AI Analysis</h1>

                <div className="bg-white rounded-md md:p-4">

					<div className="md:flex md:flex-row">
						<AiConfig 
							aiSupplement={userData?.account_data.ai_supplement || {'summary': '', 'demographics': '', 'ad_goals': ''}}
                            updateUserDataCallback={fetchNewAiAnalysis}
						/>

						<div className="w-full md:w-2/3 bg-[#F4F9FF] rounded-lg p-2 md:p-8 pt-12 pb-12">
							{/* AI response box 
							<TextField label="Generated Analysis" InputLabelProps={{ shrink: true }} value={
							authInfo.googleAuthed ?
								userData?.account_data.api_cache.ai
							:
								'Connect your Google Account to Access AI Campaign Analysis.'

							} className="w-full" multiline minRows={25}></TextField> 
                            */}

                            <Box className="w-full bg-[#F4F9FF] rounded-lg p-2 md:p-8 pt-12 pb-12">
                              {isLoading ? (
                                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                  <CircularProgress /> {/* Loading indicator */}
                                </Box>
                              ) : (
                                <Typography
                                  component="div"
                                  dangerouslySetInnerHTML={{ __html: aiString }} // Render the HTML content
                                />
                              )}
                            </Box>							
						</div>
					</div>

				</div>

            </main>
        </div>
    
    );
}
