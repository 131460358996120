

export default function ServiceDisplay({ title, paragraph, image }: { title: string, paragraph: string, image: any }) {
	return (
		<div className="text-center p-6 lg:p-0 pt-8 ">
			<h1 className="text-4xl lg:text-5xl font-extrabold">{title}</h1>
			<p className="text-xl lg:text-lg pt-4 pb-4">{paragraph}</p>

			<img src={image} className="lg:p-0 m-auto"/>
		</div>
	);
}
