import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Sidebar from "../ui/sidebar";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import TextField from '@mui/material/TextField';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, CircularProgress, Backdrop, Modal } from '@mui/material';
import BookingList from './booking-log';
import TopNav from '../ui/top-nav';
import { AuthContext } from "../auth";
import { Loupe, UploadFile } from '@mui/icons-material';


import { MDate, newMDate, addSecondsToDate } from "../types";


// Contact profile imports
import ContactProfile from "./profile";


export default function Bookings() {

	const authInfo = useContext(AuthContext);
	const form = useRef(null);

    const [time, setTime] = useState([0,0]);
    const [date, setDate] = useState(new Date());
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [notes, setNotes] = useState("");

    const [viewBookings, setViewBookings] = useState(false);
    const [addingBooking, setAddingBooking] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
	const [windowSize, setWindowSize] = useState(window.innerWidth);

	
	if(!authInfo) throw new Error("Invalid auth context.");
	const userData = authInfo.userData;


	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
	}, [authInfo.fetchingAuth]);

	function handleResize() {
		setWindowSize(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {window.removeEventListener("resize", handleResize)};
	});

    return (
        <div className="bg-slate-50 h-screen">
            <Sidebar />

            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>

            <TopNav/>

            <main className="md:ml-64 p-8 pt-28">

                {/*viewBookings ? <BookingList showFn={setViewBookings} bookings={userData['booking_list'] || []}/> : <></>*/}


                <h1 className="text-heading text-2xl pb-8">Contact Management</h1>

                <div className="bg-white w-full m-auto pt-0 flex flex-col justify-center content-center rounded-md">
                    <div className="p-8">
                        <h1 className="text-[#2196F3] text-xl mb-4">Booking Details</h1>

						{/* For mobile layout */}
						{ windowSize <= 545 ?
                        <TextField label="Search" placeholder="Name, email, etc..."/>
						:
						<></>
						}

						
                        <div className="flex flex-col md:flex-row">
							{ windowSize > 545 ?
                            <TextField label="Search" placeholder="Name, email, etc..."/>
							:
							<></>
							}

                            <button
							type="button"
							className="border-2 w-full md:w-fit rounded-md pl-4 pr-4 border-primary text-primary transition-all text-md hover:bg-[#0000000F] ml-auto"
							onClick={() => setUploadingFile(true)}>
								<UploadFile/> {windowSize > 545 ? "UPLOAD BOOKINGS FROM FILE" : ""}
							</button>
							
							{windowSize > 545 ?
							<div className="w-4"></div> : <></>}

                            <button
							type="button"
							onClick={() => setAddingBooking(true)}
                            className="bg-primary w-full md:w-fit pl-4 pr-4 rounded-md text-white transition-all text-md hover:bg-blue-font">
							 	<Loupe/> {windowSize > 545 ? "ADD NEW BOOKING" : ""}
                            </button>
                        </div>

                    </div>
                    <BookingList showFn={setViewBookings} bookings={userData?.booking_list || []}/>
                </div>

                {/* New booking form */}
                    <Modal open={addingBooking} onClose={() => setAddingBooking(false)}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="p-10 pb-6 rounded-md bg-white w-2/3 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                                <h1 className="text-2xl font-bold text-heading mb-12">Add New Booking</h1>

                                <form action="/booking" method="post" encType="application/x-www-form-urlencoded" className="w-full" onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
									// Prevent initial form submission
									e.preventDefault();

									setAddingBooking(false);
									setLoading(true);

                                    console.log("Final date:");
                                    console.log(date);


                                    let data = {
                                        booking_type: "log",
                                        notes: notes,
                                        first_name: firstName,
                                        surname: surname,
                                        date: date,
                                        phone: phone,
                                        email: email
                                    };


									let res = await fetch('/booking', {
										method: "POST",
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
										body: JSON.stringify(data)
									});

                                    //console.log(`Booking creation status:`);
                                    //console.log(res);

									authInfo.fetchUserData();
									setLoading(false);
								}}>
                                    <input type='hidden' value="log" name="type"></input>
                                    <div className='lg:flex justify-between'>

                                        <div className='flex flex-col justify-center mr-4 w-1/2'>
                                            <h1 className='text-lg text-heading'>Personal Details</h1>

                                            <div className='flex flex-row justify-between'>
                                                <TextField label="First Name" variant='outlined' margin="normal" name="first_name" className="w-[48%]" onChange={(e: any) => setFirstName(e.target.value)}/>
                                                <TextField label="Last Name" variant='outlined' margin="normal" name="surname" className="w-[48%]" onChange={(e: any) => setSurname(e.target.value)}/>
                                            </div>

                                            <h2 className="mt-2 text-heading text-md mb-2">Contact Methods</h2>
                                            <PhoneInput 
                                                defaultCountry="au"
                                                value={phone}
                                                onChange={(value) => {setPhone(value);}}
                                                className="mb-2 z-10"
                                                name="phone"
                                            />

                                            <TextField type="email" label="Email" margin="normal" name="email" placeholder="Email" InputLabelProps={{ shrink: true }} onChange={(e) => setEmail(e.target.value)}/>



                                        </div>

                                        <div className='ml-4 bg-slate-100 p-8 rounded-xl w-1/2'>

                                            <h1 className='text-heading text-lg mb-2'>Booking Time and Date</h1>
                                            <div className='flex flex-row justify-between'>
                                                <TimePicker 
                                                    className='bg-white w-[48%]' 
                                                    label="Set Time" 
                                                    onChange={(value : any) => { setTime([value.$H, value.$m]) }}
                                                    />

                                                <DatePicker 
                                                    className="bg-white w-[48%]" 
                                                    label="Set Date" 
                                                    onChange={(value: any) => {
                                                        setDate(addSecondsToDate(new Date(value["$d"]), time[0] * 3600 + time[1] * 60))
                                                    }}
                                                />
                                                <input type="hidden" value={JSON.stringify(addSecondsToDate(date, time[0] * 3600 + time[1] * 60))} name="date"></input>
                                            </div>

                                            <h1 className='text-heading text-lg mb-2'>Additional Notes</h1>
                                            <TextField multiline label="Additional Notes" placeholder="Type additional notes here" minRows={2} name='notes' className='bg-white w-full' onChange={(e) => setNotes(e.target.value)}/>


                                        </div> 

                                    </div>

                                    <div className='flex flex-row justify-end gap-4 mt-6'>
                                        <Button type='button' variant='outlined' onClick={() => setAddingBooking(false)}>Cancel</Button>
                                        <Button type='submit' variant='contained' disabled={loading}>Confirm Booking</Button>
                                    </div>

                                </form>

                            </div>
                        </LocalizationProvider>
                    </Modal>

                    {/* File upload modal */}
                    <Modal open={uploadingFile} onClose={() => {setUploadingFile(false)}} >
                        <div className="bg-white w-1/2 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 p-4 rounded-md">
                            <form action="/import" method="post" className="flex flex-col justify-center">
                                <h1 className='font-semibold text-heading mb-16'>Upload CSV Booking</h1>
                                <input name="file" type="file" className="w-1/2 self-center mb-4"></input>
                                <Button variant="outlined" className="w-1/2 self-center" type="submit">Submit</Button>
                            </form>
                        </div>
                    </Modal>

            </main>

        </div>
    );
}
