import React from 'react';
import ReactDOM from 'react-dom/client';
import PageRouter from './router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './auth';

import './index.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
	<React.StrictMode>
		<GoogleOAuthProvider clientId='781961859192-vkaf76hs3lhd27pe6llv7l4j4i7sdtfo.apps.googleusercontent.com'>

			<AuthProvider>
				<div className="font-roboto">
					<PageRouter />
				</div>
			</AuthProvider>

    	</GoogleOAuthProvider>
  	</React.StrictMode>
);
