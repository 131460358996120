import React from "react";
import { Booking } from "../types";

function ContactInformation({ contactInfo }: { contactInfo: Booking | null }) {
	

	function formatContactableInfoHeading(): string {
		if(!contactInfo) {
			return "-";
		}
		return contactInfo.contactable ? "yes" : "no";
	}

	return (
		<div className="bg-white p-8 text-main">
			{/* Personal Information */}
			<div className="pb-4">
				<h2 className="text-blue-font text-2xl font-bold ">
					{contactInfo?.first_name} {contactInfo?.last_name}
				</h2>
				<h3 className="mb-4 font-semibold">
					{contactInfo?.phone}
					<br />
					{contactInfo?.email}
				</h3>

				<p className="text-md">
					{contactInfo?.general_info}
				</p>

			</div>


			{/* Business relation info (bookings, messages, etc.) */}
			<hr />
			<div className="pt-4">
				<div>Receives automated messages?: 
					<span className="font-bold"> {formatContactableInfoHeading()}</span>
				</div>
			</div>
		</div>

	);
}

export default ContactInformation;
