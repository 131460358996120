import { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import SendIcon from "@mui/icons-material/Send";

// Type imports
import { Message } from "../types";



/* Messagebox component */
function MessageBox({ contactId, messageHistory }: { contactId: string, messageHistory: Array<Message> }) {

	const [messageContent, setMessageContent] = useState("");

	async function sendMessage() {
		
		const res = await fetch("/send-pm", 
		{
			method: "post",
			body: JSON.stringify({
				target: contactId,
				content: messageContent
			}),
		});
	}

	return (
		<div className="bg-slate-200 w-full md:w-1/2 h-64 max-h-full md:max-h-[80%]">
			
			{/* Message history */}
			<div className="flex flex-col p-6 h-full">
				{messageHistory.map((message: Message, idx: number) => (
					<div className={`
						p-2 bg-transparent
						${message.outgoing ? 
						"justify-self-end self-end" :
						""
						}
						`}
						key={idx}>
						<div 
							className={`
							w-fit p-2 rounded-lg
							${message.outgoing ? 
							"bg-[#0000FF77] text-white rounded-br-none" : 
							"bg-white rounded-bl-none"}
							`}>
							<p>{message.content}</p>
						</div>
					</div>
				))}
			</div>


			{/* New message textfield */}
			<div className="flex flex-row items-center justify-center bg-white shadow-md p-2">
				<TextField placeholder="Type your message" fullWidth onChange={(event) => {setMessageContent(event.target.value)}}/>
				<div className="w-2"></div>
				<IconButton color="primary" onClick={sendMessage}>
					<SendIcon />
				</IconButton>
			</div>
		</div>
	);
}

export default MessageBox;
