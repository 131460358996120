import { Tabs, Tab, Card, Button, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useState } from "react";
import { DateTimePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function DayList({ messageContent, setRepeating, reportData }: { messageContent: String, setRepeating: (status: boolean) => void, reportData: (data: any) => void }) {

    const [currTab, setCurrTab] = useState(0);
    const [dateTimeVal, setDateTimeVal] = useState(new Date());
    const [dayList, setDayList] = useState(new Array);
    const [dayBools, setDayBools] = useState([...Array(7)].map(() => false));

    const [repeatTime, setRepeatTime] = useState(new Object());
	

    const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    function updateDays(idx: number) {
        // Toggle correct index
        dayBools[idx] = !dayBools[idx];
        setDayBools(dayBools);

        let newDayList = [];
        for(let i=0; i<days.length; i++) {
            if(dayBools[i]) {
                newDayList.push(days[i])
            }
        }

		// Update report data with new dayList
		reportData({"datetime": dateTimeVal, "repeatDays": newDayList, "time": repeatTime});				

        setDayList(newDayList);
    }

    return (
        <div className="w-fit">
                {/* Select 1-off scheduled message or repeating message */}

                    <Tabs value={currTab} 
                    onChange={(event: React.SyntheticEvent, newVal: number) => {setCurrTab(newVal); setRepeating(newVal == 1 ? true : false );}}
                    className="self-center pb-6">
                        <Tab label="One-off" />
                        <Tab label="Repeating" />
                    </Tabs>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {currTab == 0 ? (
                            <div>
                            	<h1 className="text-md text-main text-left font-semibold pb-1">Choose a time and date to send</h1>
                                <DateTimePicker onChange={(val: Date | null) => {
									reportData({"datetime": val ? new Date(val) : new Date, "repeatDays": dayList, "time": repeatTime});
									setDateTimeVal(val ? new Date(val) : new Date);
								}}/>
                                <div className="mt-8"></div>
                            </div>
                        ):''}

                        {currTab == 1 ? (
                            <div>
                                <div>
                                    <div className="pb-4">
                                        <h1 className="text-md font-semibold text-main pb-1">Choose a Time</h1>
                                        <TimePicker onChange={(val: Date | null) => {
											reportData({"datetime": dateTimeVal, "repeatDays": dayList, "time": val || new Date()});			
											setRepeatTime(val || new Date());
										}}/>
                                    </div>

									<h1 className="text-md font-semibold text-main">Choose Days to Repeat on</h1>
                                    <FormGroup row className="mb-4">
                                        <FormControlLabel control={<Checkbox onClick={() => updateDays(0)}/>} label="Mon"/>
                                        <FormControlLabel control={<Checkbox onClick={() => updateDays(1)}/>} label="Tue"/>
                                        <FormControlLabel control={<Checkbox onClick={() => updateDays(2)}/>} label="Wed"/>
                                        <FormControlLabel control={<Checkbox onClick={() => updateDays(3)}/>} label="Thu"/>
                                        <FormControlLabel control={<Checkbox onClick={() => updateDays(4)}/>} label="Fri"/>

                                        <FormControlLabel control={<Checkbox onClick={() => updateDays(5)}/>} label="Sat"/>
                                        <FormControlLabel control={<Checkbox onClick={() => updateDays(6)}/>} label="Sun"/>
                                    </FormGroup>


                                </div>

                            </div>
                        ):''}
                    </LocalizationProvider>
        </div>
    );
}
