import { useState, useEffect, useContext } from "react";
import Sidebar from "../ui/sidebar";
import TopNav from "../ui/top-nav";
import { Card, CardContent, Button, Modal, TextField, Select, MenuItem, FormControl, InputLabel, CircularProgress, Backdrop } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

import { CampaignOverview, AdMetrics, Campaign } from "../types";
import { AuthContext } from "../auth";



function cleanCampaignData(campaigns: Array<Campaign> | undefined) {
    let newCampaignArray = new Array<AdMetrics & CampaignOverview>();

	if(campaigns === undefined) return newCampaignArray;

    if(campaigns) {
        for(let i=0; i<campaigns.length; i++) {
            // Combine into flat object
            newCampaignArray.push({...campaigns[i].campaign, ...campaigns[i].metrics});
        }
    }

    return newCampaignArray;
}


async function fetchAdsData() {
	// Request User Data
	const response = await fetch('/get-campaign-data');
	const data = await response.json();
	sessionStorage.setItem('ads-data', JSON.stringify(data));

	// Now reload page
	window.location.reload();
}


export default function AdGeneration() {

	const authInfo = useContext(AuthContext);
	if(!authInfo) throw new Error("Invalid auth context.");
	
	useEffect(() => {
		if(!authInfo.authed && !authInfo.fetchingAuth) {
			window.location.href = "/login";
		}
	}, [authInfo.fetchingAuth]);

	const userData = authInfo.userData;
	const adsData = userData?.account_data.api_cache;


	const [createAd, setCreateAd] = useState(false);
	const [campaign, setCampaign] = useState("");

	// New Campaign Data
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

    const [loading, setLoading] = useState(false);

	console.log("Start date: " + startDate + "    End Date: " + endDate);

    // Define columns for campaign data table
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Campaign Name', flex: 1 },
        { field: 'dailyBudget', headerName: 'Daily Budget', flex: 1, valueGetter: (params: GridValueGetterParams) => `${params.row.amountMicros / 1000000.0} ${userData?.account_data.currency}`},
        { field: 'clicks', headerName: 'Click Count', flex: 1},
        { field: 'conversionRate', headerName: 'Conversion Rate', flex: 1, valueGetter: (params: GridValueGetterParams) => `${params.row.clicks != '0' ? (params.row.conversions/parseInt(params.row.clicks))*100 : 0 }%` },
        { field: 'impressions', headerName: 'Impressions', flex: 1},
        { field: 'endDate', headerName: 'End Date', flex: 1}
    ];

    return (
        <div className="bg-slate-50 h-screen">

			{/* Loading animation when new ad is created */}
            <Backdrop open={loading} className="text-center z-50">
				<div className="text-white">
                	<CircularProgress color="inherit"/>
				</div>
				<div className="pl-8">
                	<h1 className="text-4xl font-bold text-white text-left">Creating Your New Ad</h1>
					<h1 className="text-3xl text-white text-left">This may take up to 1 minute.</h1>
				</div>
            </Backdrop>


			<Modal open={createAd} onClose={() => setCreateAd(false)} className="overflow-scroll">
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<Card className="w-full h-screen lg:h-fit lg:w-2/3 absolute lg:left-[16.7%] p-8 lg:mt-32">
						<h1 className="font-bold text-2xl text-heading">Create Dynamic Search Ad</h1>
						<form method="post" action="/new-ad">
							
							<div className="flex">
								<div className="w-1/2 pr-4">
									<h2 className="mt-4 text-main font-semibold mb-4">Create/Select Campaign</h2>
									<FormControl fullWidth>
										<InputLabel id="campaign-label">Create/Select Campaign</InputLabel>
										<Select labelId="campaign-label" label="Create/Select Campaign" fullWidth name="selected-campaign" value={campaign} onChange={(event: SelectChangeEvent) => setCampaign(event.target.value as string)}>
											{cleanCampaignData(adsData?.ads).map((c: any) => <MenuItem value={c['name']}>{c['name']}</MenuItem>)}
											<MenuItem autoFocus selected value="create-new-campaign" sx={{color: 'primary.main'}}><RateReviewOutlinedIcon className="mr-1"/> New Campaign</MenuItem>
										</Select>
									</FormControl>
									
									{/* New Campaign Information */}
									{ campaign === 'create-new-campaign' ?
									<>
										<h2 className="mt-4 text-main font-semibold mb-2">New Campaign Name</h2>
										<TextField fullWidth name="new-campaign-name" label="Campaign Name"/>

										<h2 className="mt-4 text-main font-semibold mb-2">Choose Campaign Start and End Date</h2>
										<div className="flex">
											<div className="pr-2">
												<input type="hidden" value={startDate} name="start-date"></input>
												<DatePicker onChange={(value: any) => setStartDate(`${value['$y']}-${value['$M']+1}-${value['$D']}`)} />
											</div>
											<div className="pl-2">
												<input type="hidden" value={endDate} name="end-date"></input>
												<DatePicker onChange={(value: any) => setEndDate(`${value['$y']}-${value['$M']+1}-${value['$D']}`)}/>
											</div>
										</div>
										
										<h2 className="mt-4 text-main font-semibold mb-2">Your Website URL</h2>
										<TextField label="Website URL" fullWidth name="domain"/>
										
										<h2 className="mt-4 text-main font-semibold mb-2">Ad Campaign Daily Budget</h2>
										<div className="flex flex-row items-center">
											<TextField label="Campaign Daily Budget" name="new-campaign-budget" />
											<div className="ml-2 text-xl text-main font-semibold">{userData?.hasOwnProperty('account_data') ? userData?.account_data.currency : ''}</div>
										</div>
									</>
									:
										<></>
									}
									
									<div className="mt-8"/>

									<TextField select name="num-ads" label="Number of Ads to Create" fullWidth defaultValue={3} >
										{
											[1,2,3,4,5].map((num) => (
												<MenuItem key={num} value={num}>
													{num}
												</MenuItem>
											))
										}
									</TextField>
								</div>


								<div className="w-1/2 pl-4">
									<h2 className="mt-4 mb-2 text-main font-semibold">Mention some topics/words/phrases you want to be present in the ads.</h2>
									<h3 className="mb-2">This is used by the AI to generate ad descriptions, so be descriptive and provide plenty of information.</h3>
									<TextField fullWidth label="Search Ad Description" multiline minRows="10" name="ad-description" required/>
								</div>
							</div>
							<div className="pt-8 flex flex-row justify-end">
								<Button variant="outlined" type="button" onClick={() => setCreateAd(false)}>Cancel</Button>
								<Button variant="contained" type="submit" disabled={loading}>Submit</Button>
							</div>
						</form>
					</Card>
				</LocalizationProvider>
			</Modal>

            <Sidebar />

            <TopNav/>
            <main className="md:ml-64 p-8 pt-28">

                <h1 className="text-2xl mb-8 text-heading">Ad Generation</h1>
				<div className="w-full p-8 bg-white rounded-md mb-8">
					<div className="flex flex-col md:flex-row align-center justify-between">
						<div>
							<h1 className="text-primary text-lg mb-4">Create a Dynamic Search Ad</h1>
							<p className="mb-4 pr-4">
								Dynamic Search Ads (DSA) use Google's AI to extract information from your webpage and 
								automatically generate ad headlines and keywords targeting.
							</p>

							<p className="mb-2 pr-4">
								Currently Startbase only supports creating new Ad Groups and cannot create an ad for an existing ad group.
							</p>

							<div className="flex flex-row pb-8 md:pb-0">
								<a href="https://support.google.com/google-ads/answer/2471185" className="mr-4 underline text-main cursor-pointer hover:text-black">Learn more about Dynamic Search Ads</a>
								{/*<a className="mr-4 underline text-main cursor-pointer hover:text-black">View DSA Example</a>*/}
							</div>
						</div>
						{
						authInfo.authed ?
						<Button variant="contained" className="h-fit self-center" onClick={() => setCreateAd(true)}>Create Dynamic Search Ad</Button>
						:
						<Button variant="contained" className="h-fit self-center" disabled={loading}>Connect your Google Account to Access Ads</Button>
						}
					</div>
				</div>

				<div className="bg-white rounded-md p-8">
					{/* List of current ad campaigns */}
					<h1 className="text-primary text-lg mb-4">Current Ad Campaigns</h1>

                    <DataGrid 
                        rows={cleanCampaignData(adsData?.ads)}
                        columns={columns}
                    />
				</div>
            </main>
        </div>
    );
}
